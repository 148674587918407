<template>
  <div class="about_box">
    <div class="about_head" v-if="aboutUs.id">
      <img class="about_head_img" :src="aboutUs.contentImgUrl || $defaultImg" />
      <div class="about_head_info">
        <div class="head_info_title">
          <img
            src="@/assets/image/page/aboutLogo.png"
            class="head_info_title_logo"
          />
          <img
            src="@/assets/image/page/aboutFont.png"
            class="head_info_title_font"
          />
        </div>
        <div class="head_info_content" v-html="aboutUs.contentDetails"></div>
      </div>
    </div>
    <!-- 联系方式 -->
    <div class="contact">
      <div class="contact_head">联系方式</div>
      <div class="contact_list">
        <div class="contact_item" v-for="(item, i) in contactList" :key="i">
          <div class="item_left">
            <img :src="item.contentImgUrl || $defaultImg" />
          </div>
          <div class="item_right">
            <div class="item_name">{{ item.contentTitle }}</div>
            <div class="item_info_tab" v-html="item.contentDetails"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- --------------------------- -->

    <!-- 官方账号 -->
    <div class="account">
      <div class="contact_head">官方账号</div>
      <div class="accout_list">
        <div class="account_item" v-for="(item, i) in accountList" :key="i">
          <img
            class="account_item_img"
            :src="item.contentImgUrl || $defaultImg"
          />
          <div class="account_item_title">{{ item.contentTitle }}</div>
        </div>
      </div>
    </div>
    <!-- ---------------------------------- -->
  </div>
</template>

<script>
import { getList } from "@/api/login";
export default {
  name: "about",
  data() {
    return {
      aboutUs: {},
      contactList: [],
      accountList: [],
    };
  },
  created() {
    this.getList1();
    this.getList2();
    this.getList3();
  },
  methods: {
    async getList1() {
      try {
        let res = await getList({
          pageNum: 1,
          pageSize: 1,
          categoryCode: "aboutUS-introduction",
        });
        if (res.code == "0") {
          this.aboutUs = res.data.cmsContent;
        } else {
          this.aboutUs = {};
        }
      } catch (error) {}
    },
    async getList2() {
      try {
        let res = await getList({
          pageNum: 1,
          pageSize: 10,
          categoryCode: "aboutUS-contact",
        });
        if (res.code == "0") {
          this.contactList = res.data.cmsContents.dataList;
        } else {
          this.contactList = [];
        }
      } catch (error) {}
    },
    async getList3() {
      try {
        let res = await getList({
          pageNum: 1,
          pageSize: 10,
          categoryCode: "aboutUS-account",
        });
        if (res.code == "0") {
          this.accountList = res.data.cmsContents.dataList;
        } else {
          this.accountList = [];
        }
      } catch (error) {}
    },
  },
};
</script>

<style scoped>
.about_box {
  padding: 0.2rem 0.7rem 0.7rem 0.7rem;
}
.about_head {
  display: flex;
  margin-bottom: 0.6rem;
}
.about_head_img {
  width: 5.3rem;
  height: 4.13rem;
  margin-right: 0.2rem;
}
.head_info_title {
  width: 5.6rem;
  height: 0.95rem;
  border-bottom: 1px solid #b29763;
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
}
.head_info_title_logo {
  width: 0.6rem;
  height: 0.68rem;
  margin-right: 0.1rem;
}
.head_info_title_font {
  width: 2.88rem;
  height: 0.53rem;
}
.head_info_content {
  height: 2.6rem;
  width: 5.67rem;
  line-height: 24px;
  font-size: 16px;
  color: #3e3a39;
  margin-bottom: 0.1rem;
  overflow: hidden;
}

.contact_head {
  width: 1.56rem;
  border-bottom: 6px solid #d80c24;
  padding-bottom: 0.1rem;
  font-size: 30px;
  color: #3e3a39;
  font-weight: 700;
  margin-bottom: 0.2rem;
}
.contact_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.contact_item {
  width: 5.5rem;
  height: 2.77rem;
  box-shadow: 0px 0px 4px 0px rgba(62, 58, 57, 0.14);
  border-radius: 4px;
  padding: 0.2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
}
.item_left {
  width: 1.8rem;
  height: 1.87rem;
  margin-right: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #d9c89c 0%, #b29763 100%);
}
.item_left img {
  width: 1.17rem;
  height: 1.23rem;
}
.item_right {
  height: 1.87rem;
  color: #3e3a39;
}
.item_name {
  font-size: 18px;
  margin-bottom: 0.2rem;
  font-weight: 700;
  max-width: 3.4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.item_info_tab {
  margin-bottom: 0.1rem;
}

.accout_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.account_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3e3a39;
  margin-bottom: 0.2rem;
  margin-right: 0.4rem;
  /* width: 16.6%; */
}
.account_item_img {
  width: 1.39rem;
  height: 1.39rem;
  margin-bottom: 10px;
}
.account_item_title {
  max-width: 1.3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
